


@media screen and (max-width:1150px) {
    .heading1{
        font-size: 4rem!important;
    }
    .intro{
        width: 75%!important;
    }
}

@media screen and (max-width:950px) {
    .heading1{
        font-size: 3rem!important;
        margin: auto!important;
        width: fit-content!important;
    }
    .headingContainer{
        margin: auto!important;
    }
    .intro{
        width: 100%!important;
        text-align: center!important;
    }
    .goToButton{
        display: block!important;
        margin:auto!important;
        margin-top: 4%!important;
    }
}
@media screen and (max-width:700px) {
    .heading1{
        font-size: 1.5rem!important;
    }
    .intro{
        
        font-size: 0.8rem!important;
    }
}

