@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  /* background-color: rgb(11,24,47); */
  /* background-color: #003B00; */
  /* background-color: #0D0208; */
  background-color:rgb(9, 24, 53);
  overflow-x: hidden;
  font-family: "Poppins","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: white;
}