.projectImage{
    border-radius: 5px;
}
.skillStack{
    transition: transform 300ms ease-in-out 100ms;
}

.skillStack:hover{
    transform: scale(1.1);
}



@media screen and (max-width:950px) {
    
    .projectContainer{
        grid-template-columns: 1fr!important;
    }
    .projects{
        width: 100%!important;
    }
    .projectImage{
        height: 210px!important;
    }
    .iconBox{
        width: 20%!important;
    }
}

@media screen and (max-width:370px) {
    
    .projectContainer{
        grid-template-columns: 1fr!important;
    }
    .projects{
        width: 100%!important;
    }
    .projectImage{
        height: 210px!important;
    }
    .iconBox{
        width: 20%!important;
    }
}