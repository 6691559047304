.skillContainer {
    transition: transform 300ms ease-in-out 100ms;
}
  
.skillContainer:hover {
   
    transform: scale(1.1);
}


@media screen and (max-width:950px) {
    
    .skillContainer{
        width: 48%!important;
    }
    
    .skillHeading{
        font-size: 1.5rem!important;
       
    }
}